export const errorCodes = {
    SUCCESSFUL_VOID_TRANSACTION: {
        statusCode: 1012
    },
    SUSPENDED_ACCOUNT: {
        statusCode: 1022
    },
    PASSWORD_IS_EXPIRED: {
        statusCode: 1026
    },
    USER_NOT_FOUND: {
        statusCode: 1003
    },
    INCORRECT_CREDENTIAL: {
        statusCode: 1023
    },
    USER_NOT_SIGNED_UP: {
        statusCode: 1031
    }
};
