export const PaymentStatus = {
    OK: 'SUCCESS',
    FAILED: 'UNSUCCESSFUL',
    DECLINE: 'UNSUCCESSFUL',
    'RISK-CHECK-DECLINE': 'UNSUCCESSFUL',
    ERROR: 'UNSUCCESSFUL',
    '3DS-PROGRESS': 'PENDING',
    REFUND: 'REFUND',
    'REDEEM-CANCEL': 'REFUND'
};
